/**
 * Clips API
 * 
 * Exposes functions to interact with the /clips endpoint:
 *   1) listClips (GET /clips) - List all clips (with optional query params)
 *   2) getClipById (GET /clips/{clipId}) - Fetch a single clip by its ID
 *   3) createClip (POST /clips) - Create a new clip
 *   4) publishClip (PUT /clips/{clipId}/publish) - Publish a clip
 *   5) getClipsByStreamId (GET /clips/stream/{streamId}) - List all clips for a specific stream
 *   6) updateClipById (PUT /clips/{clipId}) - Update a clip's attributes (and regenerate VOD if timing changes)
 *   7) deleteClip (DELETE /clips/{clipId}) - Delete a clip and all its associated files
 */

import { method } from "lodash";
import { fetchWrapperPortal } from "./fetch";

/**
 * List all clips, optionally filtered by query parameters
 * @param {Object} [params] - An object for query parameters (e.g. { stream_id: 'stream123', status: 'pending' })
 * @returns {Promise<any>} - A Promise that resolves to the response data
 */
export const listClips = async (params = {}) => {
  // Handle sort parameter separately since it's JSON
  const { sort, ...otherParams } = params;
  const queryParams = new URLSearchParams(otherParams);
  if (sort) {
    queryParams.append('sort', sort);
  }
  
  return fetchWrapperPortal({
    url: `clips${queryParams.toString() ? `?${queryParams.toString()}` : ''}`,
    method: "GET",
    addBearer: true,
  });
};

/**
 * Get details for a specific clip by its ID
 * @param {string} clipId - The ID of the clip to retrieve
 * @returns {Promise<any>} - A Promise that resolves to the response data
 */
export const getClipById = async (clipId) =>
  fetchWrapperPortal({
    url: `clips/${clipId}`,
    method: "GET",
    addBearer: true,
  });

/**
 * Create a new clip
 * @param {Object} clipData - The details required to create a clip (e.g. { streamId, title, status, startTime, endTime })
 * @returns {Promise<any>} - A Promise that resolves to the newly created clip data
 */
export const createClip = async (clipData) =>
  fetchWrapperPortal({
    url: `clips`,
    method: "POST",
    addBearer: true,
    body: JSON.stringify(clipData),
  });

/**
 * Publish a clip by its ID
 * @param {string} clipId - The ID of the clip to publish
 * @returns {Promise<any>} - A Promise that resolves to the updated clip data
 */
export const publishClip = async (clipId) =>
  fetchWrapperPortal({
    url: `clips/${clipId}/publish`,
    method: "PUT",
    addBearer: true,
  });

/**
 * Retrieve all clips associated with a specific stream
 * @param {string} streamId - The ID of the stream to fetch clips for
 * @returns {Promise<any>} - A Promise that resolves to an array of clip documents
 */
export const getClipsByStreamId = async (streamId) =>
  fetchWrapperPortal({
    url: `clips/stream/${streamId}`,
    method: "GET",
    addBearer: true,
  });

/**
 * Update a clip's attributes and regenerate VOD if timing values change
 * @param {string} clipId - The ID of the clip to update
 * @param {Object} clipData - Fields to update (e.g. { title, startTime, endTime, ... })
 * @returns {Promise<any>} - A Promise that resolves to the updated clip document
 */
export const updateClip = async (clipId, clipData) =>
  fetchWrapperPortal({
    url: `clips/${clipId}`,
    method: "PUT",
    addBearer: true,
    body: JSON.stringify(clipData),
  });

/**
 * Delete a clip and all its associated files
 * @param {string} clipId - The ID of the clip to delete
 * @returns {Promise<any>} - A Promise that resolves to a success message or an error
 */
export const deleteClip = async (clipId) =>
  fetchWrapperPortal({
    url: `clips/${clipId}`,
    method: "DELETE",
    addBearer: true,
  });

export const generateAIClips = async (streamId) => {}
  // fetchWrapperPortal({
  //   url: `clips/generate/${streamId}`,
  //   method: "GET",
  //   addBearer: true,
  // })